import React, {useState} from "react";
import logo from './assets/logo.svg';
import './App.css';
import QrReader from 'react-qr-reader'
import {ReactComponent as Logo} from "./assets/logo.svg";
import Modal from "react-modal";
import {getMobileOperatingSystem} from "./utils";
import IosPopup from "./components/IosPopup";
import AndroidPopup from "./components/AndroidPopup";

function App() {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleScan = data => {
        if (data) {
            // alert(data);
            if (data.includes("menyja.co/menu")) {
                // window.location.replace(data);
                if(window.location.pathname === "/dev-tools") {
                    const token = data.split("=")[1];
                    const decoded_token = atob(token);
                    const table_number_query = decoded_token.split("&")[1];
                    const table_number = table_number_query.split("=")[1];
                    alert("Table number: " + table_number);
                } else {
                    window.location.href = '//' + data
                }
            }
        }
    }
    
    const handleError = err => {
        console.log(err);
        setIsModalOpen(true);
    }
    
    const handleClose = () => {
        setIsModalOpen(false);
    }
    
    return (
        <div className="App">
            <div className="scan-container">
                <div className="scan-logo">
                    <Logo/>
                </div>
                <div className="scan-subtitle">
                    Skano QR kodin për të parë menynë
                </div>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{width: '100%'}}
                />
                
                <div className="scanner-overlay"/>
                <div className="scanner-box"/>
            </div>
            
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={false}
                className="custom-modal"
                overlayClassName="custom-modal-overlay">
                
                <div className="modal-container">
                    {getMobileOperatingSystem() === "iOS" ?
                        <IosPopup/> : <AndroidPopup/>}
                </div>
                
                <button onClick={() => handleClose()}
                        className="modal-button">
                    Në rregull
                </button>
                
            </Modal>
        </div>
    );
}

export default App;
