import React from "react";

function AndroidPopup() {
    return (
        <div className="android-popup">
            <p className="android-popup-text">Nëse jeni në një pajisje Samsung, ju lutemi hapni <a href="https://scan.menyja.co">scan.menyja.co</a> në: </p>
            <p className="android-popup-link"><a href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser&hl=en&gl=US">Samsung Internet Browser</a> </p>
    <hr/>
            <p className="android-popup-text">Nëse jeni në një pajisje tjetër, ju lutemi hapni <a href="https://scan.menyja.co">scan.menyja.co</a> në: </p>
            <p className="android-popup-link"><a href="https://play.google.com/store/apps/details?id=com.android.chrome" className="android-popup-link">Google Chrome</a></p>
        </div>
    );
}

export default AndroidPopup;
