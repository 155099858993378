import React from "react";
import Safari from "../assets/safari.png";

function IosPopup() {
    return (
        <div className="ios-popup">
            <div className="safari-icon">
                <img src={Safari} alt="Safari"/>
            </div>
            <p>Ju lutemi hapni <a href="https://scan.menyja.co">scan.menyja.co</a> në Safari</p>
        </div>
    );
}

export default IosPopup;
